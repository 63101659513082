import { SEVERITY } from '../../toast/Toast';
import { downscaleImage } from './downscaleImage';
import { file2b64 } from './file2b64';
import { ImageDrop } from './quill.imageDrop';

interface IOptions {
  maxWidth: number | null;
  maxHeight: number | null;
  quality: number | null;
  imageType: string | null;
  debug?: any | null;
}

function warnAboutOptions(options: IOptions) {
  // Safe-ify Options
  options.maxWidth = options.maxWidth || 1000;
  options.maxHeight = options.maxHeight || 1000;

  if (options.maxWidth && typeof options.maxWidth !== 'number') {
    console.warn(
      `quill.imageCompressor:`,
      `[config error] 'maxWidth' is required to be a "number" (in pixels), 
recieved: ${options.maxWidth}
-> using default 1000`
    );
    options.maxWidth = 1000;
  }
  if (options.maxHeight && typeof options.maxHeight !== 'number') {
    console.warn(
      `quill.imageCompressor:`,
      `[config error] 'maxHeight' is required to be a "number" (in pixels), 
recieved: ${options.maxHeight}
-> using default 1000`
    );
    options.maxHeight = 1000;
  }
  if (options.quality && typeof options.quality !== 'number') {
    console.warn(
      `quill.imageCompressor:`,
      `[config error] 'quality' is required to be a "number", 
recieved: ${options.quality}
-> using default 0.7`
    );
    options.quality = 0.7;
  }
  if (options.imageType && (typeof options.imageType !== 'string' || !options.imageType.startsWith('image/'))) {
    console.warn(
      `quill.imageCompressor:`,
      `[config error] 'imageType' is required be in the form of "image/png" or "image/jpeg" etc ..., 
recieved: ${options.imageType}
-> using default image/jpeg`
    );
    options.imageType = 'image/jpeg';
  }
}

class imageCompressor {
  quill: any;
  range: any;
  options: any;
  imageDrop: any;
  fileHolder: any;
  DEBUG: boolean;

  constructor(quill: any, options: IOptions) {
    this.quill = quill;
    this.range = null;
    this.options = options;
    this.DEBUG = (options && options.debug) || false;

    const onImageDrop = async (dataUrl: any) => {
      //@ts-ignore
      this.DEBUG && console.debug('onImageDrop', { dataUrl });
      const dataUrlCompressed = await this.downscaleImageFromUrl(dataUrl);
      this.insertToEditor(dataUrlCompressed);
    };
    this.imageDrop = new ImageDrop(quill, onImageDrop, console);
    warnAboutOptions(options);
    //@ts-ignore
    this.DEBUG && console.debug('fileChanged', { options, quill });

    let toolbar = this.quill.getModule('toolbar');
    if (toolbar) {
      toolbar.addHandler('image', () => this.selectLocalImage());
    } else {
      console.error(
        'quill.imageCompressor:',
        'Quill toolbar module not found! need { toolbar: // options } in Quill.modules for image icon to sit in'
      );
    }
  }

  selectLocalImage() {
    this.range = this.quill.getSelection();
    this.fileHolder = document.createElement('input');
    this.fileHolder.setAttribute('type', 'file');
    this.fileHolder.setAttribute('accept', 'image/*');
    this.fileHolder.setAttribute('style', 'visibility:hidden');

    this.fileHolder.onchange = () => this.fileChanged();

    document.body.appendChild(this.fileHolder);

    this.fileHolder.click();

    window.requestAnimationFrame(() => {
      document.body.removeChild(this.fileHolder);
    });
  }

  async fileChanged() {
    const file = this.fileHolder.files[0];
    //@ts-ignore
    this.DEBUG && console.debug('fileChanged', { file });
    if (!file) {
      return;
    }
    if (file.size > 10485760) {
      this.options.toastDispatch({ msg: 'Image size must be under 10mb', severity: SEVERITY.ERROR });
      return;
    }
    const base64ImageSrc = await file2b64(file);
    const base64ImageSmallSrc = await this.downscaleImageFromUrl(base64ImageSrc);
    this.insertToEditor(base64ImageSmallSrc);
  }

  async downscaleImageFromUrl(dataUrl: any) {
    const logger = console;
    const dataUrlCompressed = await downscaleImage(
      dataUrl,
      this.options.maxWidth,
      this.options.maxHeight,
      this.options.imageType,
      this.options.quality,
      logger,
      this.DEBUG
    );
    //@ts-ignore
    this.DEBUG && console.debug('downscaleImageFromUrl', ` ${dataUrl}, ${dataUrlCompressed}`);
    return dataUrlCompressed;
  }

  insertToEditor(url: any) {
    //@ts-ignore
    this.DEBUG && console.debug('insertToEditor', `${url}`);
    this.range = this.quill.getSelection();
    const range = this.range;
    // Insert the compressed image
    this.logFileSize(url);
    this.quill.insertEmbed(range.index, 'image', `${url}`, 'user');
    // Move cursor to next position
    range.index++;
    this.quill.setSelection(range, 'api');
  }

  logFileSize(dataUrl: any) {
    const head = 'data:image/png;base64,';
    const fileSizeBytes = Math.round(((dataUrl.length - head.length) * 3) / 4);
    const fileSizeKiloBytes = (fileSizeBytes / 1024).toFixed(0);
    this.DEBUG && console.debug('quill.imageCompressor', `estimated img size: ${fileSizeKiloBytes} kb`);
  }
}

export { imageCompressor };
